<button
    mat-button celum-square-button
    [matMenuTriggerFor]="menuRef"
    [matTooltip]="'SHARED.MORE_ACTIONS' | translate"
    (click)="$event.stopPropagation()"
    data-cy="custom-forms-context-menu-trigger">
  <celum-icon [configuration]="menuIcon"></celum-icon>
</button>

<mat-menu #menuRef xPosition="before">
  <ng-template matMenuContent>
    <div>
      <button
          mat-menu-item class="custom-forms-context-menu_item"
          (click)="copyAsNewDialogOpened.emit()"
          data-cy="custom-forms-context-menu_copy-to-new">
        <celum-icon
            class="custom-forms-context-menu_item_icon"
            [configuration]="copyIcon">
        </celum-icon>
        <span>{{ 'TEAMSPACE_MANAGEMENT.FORMS.TABLE.CONTEXT_MENU.COPY_TO_NEW' | translate }}</span>
      </button>
      <button
          mat-menu-item class="custom-forms-context-menu_item"
          (click)="deleteDialogOpened.emit()"
          data-cy="custom-forms-context-menu_delete">
        <celum-icon
            class="custom-forms-context-menu_item_icon"
            [configuration]="deleteIcon">
        </celum-icon>
        <span>{{ 'TEAMSPACE_MANAGEMENT.FORMS.TABLE.CONTEXT_MENU.DELETE' | translate }}</span>
      </button>
    </div>
  </ng-template>
</mat-menu>
