import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { IconConfiguration, ShowSnackbar, SimpleSnackbar, SnackbarConfiguration } from '@celum/common-components';

import { CreateTemplate, FetchTemplateCategories, FetchTemplateCategoriesSuccess } from './template-chooser.actions';
import { TemplateChooserService } from './template-chooser.service';

@Injectable()
export class TemplateChooserEffects {
  public createTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateTemplate),
      mergeMap(({ template, people, category }) =>
        this.service.create(template, people).pipe(
          map(() => {
            const icon = IconConfiguration.medium('icon-green-save');

            return new ShowSnackbar(
              'success',
              SimpleSnackbar,
              SnackbarConfiguration.success(this.getSnackbarMessage(category)).withIcon(icon)
            );
          }),
          catchError(() =>
            of(
              new ShowSnackbar(
                'error',
                SimpleSnackbar,
                SnackbarConfiguration.error('WORKROOM_WIZARD.DIALOG.WORKROOM.SAVE_TEMPLATE_DIALOG.ERROR')
              )
            )
          )
        )
      )
    )
  );

  public fetchTemplateCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FetchTemplateCategories),
      mergeMap(() =>
        this.service.fetchTemplateCategories().pipe(
          map(() => FetchTemplateCategoriesSuccess()),
          catchError(() =>
            of(new ShowSnackbar('error', SimpleSnackbar, SnackbarConfiguration.error('ERROR.UNEXPECTED_ERROR')))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private service: TemplateChooserService,
    private translateService: TranslateService
  ) {}

  private getSnackbarMessage(category: string): string {
    return this.translateService.instant('WORKROOM_WIZARD.DIALOG.WORKROOM.SAVE_TEMPLATE_DIALOG.SNACKBAR', {
      category
    });
  }
}
