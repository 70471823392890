<celum-dialog (onCloseAll)="cancel()">
  <celum-dialog-header class="invite-user-dialog invite-user-dialog_header">
    <span>{{ headerText | translate }}</span>
    <role-information-dialog></role-information-dialog>
  </celum-dialog-header>

  <celum-dialog-content>
    <div class="invite-user-dialog_people_headline">{{ 'PEOPLE.INVITE.HEADLINE.PEOPLE' | translate }}</div>
    <people-lookup
        [additionalInfo]="invitedText | translate"
        [noElementFoundText]="'PEOPLE.INVITE.NO_USER_OR_INVALID_MAIL' | translate"
        [organisationMembersOnly]="organisationMembersOnly"
        [placeholderText]="invitePlaceholderText | translate"
        [minCharText]="minCharText | translate"
        [selectedPeople]="selectedPersons || []"
        [teamspaceId]="teamspaceId"
        [statusesToDisplay]="statusesToDisplay"
        [alreadyInvited]="alreadyInvited"
        [initialPeopleSearchString]="initialPeopleSearchString"
        (emailAdded)="onEmailAdded($event)"
        (itemSelected)="onItemSelected($event)">
    </people-lookup>

    <div
        [class.invited-user-list--restrict]="(avatarConfigs$ | async)?.length > 3"
        [class.invited-user-list--info-appended]="infoNoteKey && selection.length > 0"
        class="invited-user-list flex-column-scrollbar celum-scrollbar">
      <multiline-template-person *ngFor="let avatar of avatarConfigs$ | async; trackBy: trackByFn"
          [withStatus]="false"
          [person]="avatar.person"
          [invitationStatus]="avatar.person.invitationStatus"
          [deletable]="true"
          (deleted)="onRemoveUser(avatar)"
          (roleChanged)="onRoleChanged(avatar, $event)"
          class="invited-user"></multiline-template-person>
    </div>

    <info-note
        *ngIf="infoNoteKey && selection.length > 0"
        class="invite-user-dialog_info-note"
        [iconSize]="20"
        [text]="infoNoteKey">
    </info-note>

    <div *ngIf="showMessage" class="invite-user-dialog_message" data-component-id="message">
      <div class="invite-user-dialog_message_headline">{{ 'PEOPLE.INVITE.HEADLINE.MESSAGE' | translate }}</div>
      <mat-form-field celum-form-field class="invite-user-dialog_form-field invite-user-dialog_form-field_textarea" [reserveHintSpace]="true">
      <textarea
          matInput
          cdkTextareaAutosize
          class="invite-user-dialog_message_text"
          [(ngModel)]="message"
          [maxLength]="maxMessageLength"
          [placeholder]="'PEOPLE.INVITE.MESSAGE_PLACEHOLDER' | translate"
          [cdkAutosizeMinRows]="1"
          [cdkAutosizeMaxRows]="3">
      </textarea>
        <mat-hint *ngIf="showRemainingMessageCharsHint">{{ 'INPUT_HINTS.REMAINING_CHARS' | translate: remainingMessageChars }}</mat-hint>
      </mat-form-field>
    </div>
  </celum-dialog-content>

  <celum-dialog-footer
      [confirmBtnText]="confirmBtnText | translate"
      [color]="color"
      [loading]="loading"
      [disableOnEnterConfirmation]="true"
      [valid]="this.selection?.length > 0"
      (onConfirm)="onOkClicked()"
      (onCancel)="cancel()">
  </celum-dialog-footer>
</celum-dialog>

