import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { PortalsUpsertMany } from './portal.actions';
import { Portal, PortalState } from './portal.model';
import { EntityUtil } from '../../entity-util';

export const portalAdapter: EntityAdapter<Portal> = createEntityAdapter<Portal>();

export const initialState: PortalState = portalAdapter.getInitialState();

const properties = ['name', 'link'];

const reducer = createReducer(
  initialState,
  on(PortalsUpsertMany, (state: PortalState, { portals }) => {
    const changedPortals = EntityUtil.changedEntities(properties, portals, state.entities);
    return portalAdapter.upsertMany(changedPortals, state);
  })
);

export function portalReducer(state: PortalState = initialState, action: Action): PortalState {
  return reducer(state, action);
}
