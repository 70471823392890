import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';

import { Systembar as SystembarConfig } from '@celum/internal-components';
import { ReactiveComponent } from '@celum/ng2base';
import { LocalStorageService } from '@celum/work/app/core';
import { RouterNavigationHelperService } from '@celum/work/app/core/router-navigation-helper.service';
import { PortraitRestrictorComponent } from '@celum/work/app/shared/components/portrait-restrictor/portrait-restrictor.component';

import { SystembarService } from './core/systembar.service';
import { WorkroomAvatarConfiguration } from './shared/components/workroom-avatar/workroom-avatar-configuration';

@Component({
  selector: 'workroom-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends ReactiveComponent implements OnInit, AfterViewInit {
  public systemBarCollapsed = true;
  public pending$: Observable<boolean>;
  public systembarConfig$: Observable<SystembarConfig>;
  public smallAvatarConfig$: Observable<WorkroomAvatarConfiguration>;
  public bigAvatarConfig$: Observable<WorkroomAvatarConfiguration>;

  private readonly BIG_AVATAR_SIZE: number = 63;

  private previousRouterErrorHandler: Router['errorHandler'];
  private overlayRef: OverlayRef;

  constructor(
    private storageService: LocalStorageService,
    private router: Router,
    private systembar: SystembarService,
    private overlay: Overlay,
    private deviceDetectorService: DeviceDetectorService,
    private routerNavigationHelperService: RouterNavigationHelperService
  ) {
    super();
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'portrait-restrictor_backdrop',
      panelClass: 'portrait-restrictor_panel'
    });
    this.overlayRef.backdropClick().pipe(take(1)).subscribe();
  }

  public ngOnInit(): void {
    this.configureSystemBar();
    this.storageService.testLocalStorage();

    this.pending$ = this.routerNavigationHelperService.navigationPending$;

    // https://jira.celum.company/browse/WR2-1465: deep links in Edge
    if (!this.previousRouterErrorHandler) {
      this.previousRouterErrorHandler = this.router.errorHandler;
      this.router.errorHandler = (err: any) => {
        if (new RegExp(/Loading chunk [\w\W]+ failed/, `i`).test(err.message)) {
          return null;
        }
        return this.previousRouterErrorHandler.apply(this.previousRouterErrorHandler, err);
      };
    }
  }

  public ngAfterViewInit(): void {
    this.checkMobileTabletOrientation();
  }

  private checkMobileTabletOrientation() {
    if (!(this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet())) {
      return;
    }

    const checkOrientation = () => {
      if (window.innerHeight > window.innerWidth) {
        if (!this.overlayRef.hasAttached()) {
          const componentPortal = new ComponentPortal(PortraitRestrictorComponent);
          this.overlayRef.attach(componentPortal);
        }
      } else {
        this.overlayRef.detach();
      }
    };

    checkOrientation();
    fromEvent(window, 'resize').pipe(takeUntil(this.unsubscribe$), debounceTime(500)).subscribe(checkOrientation);
  }

  private configureSystemBar() {
    this.smallAvatarConfig$ = this.systembar.getUserAvatar();
    this.bigAvatarConfig$ = this.smallAvatarConfig$.pipe(
      map(config => {
        config.size = this.BIG_AVATAR_SIZE;
        return config;
      })
    );

    this.systembarConfig$ = this.systembar.config$;
  }
}
