import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { flatten } from 'lodash';
import { EMPTY, pipe } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '@celum/authentication';
import { AvatarService } from '@celum/work/app/core/avatar/avatar.service';
import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';
import { TranslationInitializerService } from '@celum/work/app/core/translations/translation-initializer.service';

import {
  UiStateAccountAccessesLoad,
  UiStateAccountAccessesLoaded,
  UiStateChangeLanguage,
  UiStateSetLanguage
} from './ui-state.actions';
import { hasActiveLicense, Language, LicenseTypes } from './ui-state.model';
import { UIStateService } from './ui-state.service';
import { AuthLogout } from '../auth/auth.actions';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable()
export class UiStateEffects {
  public setTranslateServiceLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        pipe(
          ofType(UiStateChangeLanguage),
          tap(({ language }) => this.translationInitializerService.changeLanguage(language || Language.ENGLISH)),
          switchMap(({ language }) => this.uiService.setLanguageInSACC(language))
        )
      ),
    { dispatch: false }
  );

  public saccData$ = createEffect(() =>
    this.actions$.pipe(
      pipe(
        ofType(UiStateAccountAccessesLoad),
        exhaustMap(() =>
          this.uiService.fetchCurrentUserDataFromSACC().pipe(
            switchMap(({ accountAccesses, locale }) => {
              const isActiveMember = accountAccesses.some(accAccess => accAccess.status === 'ACTIVE');
              const allLicenses = flatten(accountAccesses.map(({ licenses }) => licenses));
              const hasActiveWorkroomLicense = hasActiveLicense(allLicenses, LicenseTypes.WorkroomsLicense);

              if (!isActiveMember || !hasActiveWorkroomLicense) {
                console.warn('User not active in any company, redirecting to SACC');
                window.location.assign((window as any).Celum.properties.saccHttpBaseAddress);
                return EMPTY;
              }

              this.translationInitializerService.changeLanguage(locale || Language.ENGLISH);

              return [UiStateAccountAccessesLoaded({ accountAccesses }), UiStateSetLanguage({ language: locale })];
            }),
            catchError(error => {
              this.errorHandler.handleError(error);
              return EMPTY;
            })
          )
        )
      )
    )
  );

  public logout$ = createEffect(
    () =>
      this.actions$.pipe(
        pipe(
          ofType(AuthLogout),
          map(() => {
            this.authService.signOut();
            this.localStorageService.clear();
          })
        )
      ),
    { dispatch: false }
  );

  public uiStateAccountAccessesLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UiStateAccountAccessesLoaded),
        map(({ accountAccesses }) => this.avatarService.addTokensToStorage(accountAccesses))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private translationInitializerService: TranslationInitializerService,
    private uiService: UIStateService,
    private avatarService: AvatarService,
    private errorHandler: FailureHandler,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {}
}
