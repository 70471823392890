import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'libs/internal-components/src/lib/systembar/model/user/user';
import { flatten, map as lodashMap, uniq } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { AVATAR_SIZE, ColorConstants, IconConfiguration } from '@celum/common-components';
import { BrowserCheck, CelumPropertiesProvider } from '@celum/core';
import {
  Avatar,
  CelumDialogOpener,
  Languages,
  ServiceTabItem,
  SimpleContextMenuItem,
  Systembar as SystembarConfig
} from '@celum/internal-components';
import { TenantService } from '@celum/work/app/core/auth/tenant.service';
import { VersionService } from '@celum/work/app/core/version/version.service';
import {
  DownloadDriveDialog,
  DownloadDriveDialogConfiguration
} from '@celum/work/app/shared/components/download-drive-dialog/download-drive-dialog';
import { FullscreenDialogComponent } from '@celum/work/app/shared/components/fullscreen-dialog/fullscreen-dialog.component';
import {
  WorkroomAvatarConfigBuilder,
  WorkroomAvatarConfiguration
} from '@celum/work/app/shared/components/workroom-avatar/workroom-avatar-configuration';
import { AvatarUtil } from '@celum/work/app/shared/util/avatar-util';
import { TeamspaceManagementComponent } from '@celum/work/app/teamspace-management/components/teamspace-management-dialog/teamspace-management.component';

import { AuthLogout } from './auth/auth.actions';
import { UiStateChangeLanguage } from './ui-state/ui-state.actions';
import {
  AccountAccessRole,
  CHLicense,
  hasActiveLicense,
  isLicenseType,
  Language,
  LicenseTypes
} from './ui-state/ui-state.model';
import {
  selectAccountAccesses,
  selectCurrentAccountAccess,
  selectLoggedInPerson,
  selectUiStateLanguage
} from './ui-state/ui-state.selectors';

@Injectable({ providedIn: 'root' })
export class SystembarService {
  public config$: Observable<SystembarConfig> = combineLatest([
    this.getUser(),
    this.getServices(),
    this.getAvatarConfiguration(),
    this.getHelpContextMenuItems(),
    this.getLanguages()
  ]).pipe(
    map(([user, services, avatar, helpContextMenuItems, languages]) => ({
      user,
      services,
      avatar,
      helpContextMenuItems,
      languages
    }))
  );

  private links = {
    help: CelumPropertiesProvider.properties.helpLinks[this.translateService.currentLang],
    termsConditions: CelumPropertiesProvider.properties.termsConditionsLink,
    privacyPolicy: CelumPropertiesProvider.properties.privacyPolicyLink
  };

  constructor(
    private store: Store<any>,
    private translateService: TranslateService,
    private versionService: VersionService,
    private dialogOpener: CelumDialogOpener,
    private matDialog: MatDialog,
    private deviceDetectorService: DeviceDetectorService,
    private tenantService: TenantService,
    private router: Router,
    private avatarUtil: AvatarUtil
  ) {}

  public getUserAvatar(): Observable<WorkroomAvatarConfiguration> {
    return this.store
      .select(selectLoggedInPerson)
      .pipe(
        switchMap(person =>
          person
            ? this.avatarUtil.getAvatarConfigWithImage({ person, size: AVATAR_SIZE.m, interactive: true })
            : of(new WorkroomAvatarConfigBuilder().placeHolder(AVATAR_SIZE.m).build())
        )
      );
  }

  private getUser(): Observable<User> {
    return this.store.select(selectLoggedInPerson);
  }

  private getServices(): Observable<ServiceTabItem[]> {
    return combineLatest([
      this.getWorkRoomsConfiguration(),
      this.getContentHubConfiguration(),
      this.getLibrariesConfiguration(),
      this.getExperienceConfiguration()
    ]).pipe(
      map(([wrContextItems, chContextItems, slibContextItems, expContextItems]) => [
        new ServiceTabItem({
          text: 'Content',
          iconConfiguration: IconConfiguration.large('content-hub-main').withColor(ColorConstants.BLUE_GRAY_050),
          contextMenuItems: chContextItems,
          active: false
        }),
        new ServiceTabItem({
          text: 'Work',
          iconConfiguration: IconConfiguration.large('workroom').withColor(ColorConstants.BLUE_GRAY_050),
          contextMenuItems: wrContextItems,
          active: true,
          selected: true,
          onClick: () => this.router.navigate(['/dashboard'])
        }),
        new ServiceTabItem({
          text: 'Libraries',
          iconConfiguration: IconConfiguration.large('libraries').withColor(ColorConstants.BLUE_GRAY_050),
          contextMenuItems: slibContextItems,
          active: false
        }),
        new ServiceTabItem({
          text: 'Experience',
          iconConfiguration: IconConfiguration.large('experience').withColor(ColorConstants.BLUE_GRAY_050),
          contextMenuItems: expContextItems,
          active: false
        })
      ])
    );
  }

  private getLanguages(): Observable<Languages> {
    return this.store.select(selectUiStateLanguage).pipe(
      map(activeLanguageKey => {
        const availableLanguages = new Map<Language, string>();
        availableLanguages.set(Language.ENGLISH, 'LANGUAGE.EN');
        availableLanguages.set(Language.GERMAN, 'LANGUAGE.DE');

        if ((window as any).Celum.properties.features.frenchLanguage) {
          availableLanguages.set(Language.FRENCH, 'LANGUAGE.FR');
        }

        return {
          availableLanguages,
          activeLanguageKey,
          onClick: (selectedLanguage: Language) =>
            this.store.next(UiStateChangeLanguage({ language: selectedLanguage }))
        };
      })
    );
  }

  private getContentHubConfiguration(): Observable<SimpleContextMenuItem[]> {
    // SACC required functionality only
    const extractCHLicenses = licenses =>
      licenses.filter(license => isLicenseType<CHLicense>(license, LicenseTypes.ContentHubLicense));

    return this.store.select(selectAccountAccesses).pipe(
      map(accountAccesses =>
        flatten(
          (accountAccesses || [])
            .filter(
              accountAccess =>
                accountAccess.status === 'ACTIVE' &&
                hasActiveLicense(accountAccess.licenses, LicenseTypes.ContentHubLicense)
            )
            .map(({ licenses }) => licenses)
        )
      ),
      map(allLicenses => extractCHLicenses(allLicenses)),
      map(chLicenses => flatten(chLicenses.map(({ chRepositories }) => chRepositories))),
      map(chRepositories => uniq(lodashMap(chRepositories, 'url'))),
      map((urls: string[]) =>
        urls
          .sort((url1, url2) => (url1 ?? '').localeCompare(url2))
          .map(url => ({
            text: url,
            onClick: () => this.openLink(url, '_self')
          }))
      )
    );
  }

  private getWorkRoomsConfiguration(): Observable<SimpleContextMenuItem[]> {
    return this.store.select(selectAccountAccesses).pipe(
      map(accountAccesses => {
        const tenant = this.tenantService.getStoredTenant();
        const contextMenuItems: SimpleContextMenuItem[] = (accountAccesses || [])
          .filter(
            accountAccess =>
              accountAccess.status === 'ACTIVE' &&
              hasActiveLicense(accountAccess.licenses, LicenseTypes.WorkroomsLicense)
          )
          .slice()
          .sort((account1, account2) => account1.accountName.localeCompare(account2.accountName))
          .map(accountAccess => ({
            text: accountAccess.accountName,
            dataComponentId: tenant === accountAccess.accountId ? 'active-tenant' : '',
            onClick: () => this.tenantService.switchTenant(accountAccess.accountId)
          }));

        if (contextMenuItems.length === 1) {
          contextMenuItems[0].onClick = () => this.router.navigate(['dashboard']);
        }

        return contextMenuItems;
      })
    );
  }

  private getLibrariesConfiguration(): Observable<SimpleContextMenuItem[]> {
    return this.getServiceConfiguration(LicenseTypes.ExperienceLicense, (window as any).Celum.properties.librariesUrl);
  }

  private getExperienceConfiguration(): Observable<SimpleContextMenuItem[]> {
    return this.getServiceConfiguration(LicenseTypes.ExperienceLicense, (window as any).Celum.properties.experienceUrl);
  }

  private getServiceConfiguration(license: LicenseTypes, serviceUrl: string): Observable<SimpleContextMenuItem[]> {
    return this.store.select(selectAccountAccesses).pipe(
      map(accountAccesses => {
        const tenant = this.tenantService.getStoredTenant();
        const contextMenuItems: SimpleContextMenuItem[] = (accountAccesses || [])
          .filter(
            accountAccess => accountAccess.status === 'ACTIVE' && hasActiveLicense(accountAccess.licenses, license)
          )
          .slice()
          .sort((account1, account2) => account1.accountName.localeCompare(account2.accountName))
          .map(accountAccess => {
            return {
              text: accountAccess.accountName,
              dataComponentId: tenant === accountAccess.accountId ? 'active-tenant' : '',
              onClick: () => this.openLink(`${serviceUrl}/tenant/${accountAccess.accountId}`, '_self')
            };
          });

        if (contextMenuItems.length === 0) {
          contextMenuItems.push({
            text: '',
            dataComponentId: '',
            onClick: () => this.openLink(serviceUrl, '_self')
          });
        }

        return contextMenuItems;
      })
    );
  }

  private getAvatarConfiguration(): Observable<Avatar> {
    return this.store.select(selectCurrentAccountAccess).pipe(
      filter(accAccess => !!accAccess),
      map(accAccess => {
        const items: SimpleContextMenuItem[] = [];

        items.push(
          {
            text: 'CELUM.CLOUD_ACCOUNT',
            onClick: () => this.openLink((window as any).Celum.properties.saccHttpBaseAddress)
          },
          {
            text: 'TEAMSPACE_MANAGEMENT.HEADLINE',
            onClick: () => this.matDialog.open(TeamspaceManagementComponent, FullscreenDialogComponent.dialogConfig())
          }
        );

        if (accAccess.role === AccountAccessRole.MANAGER) {
          items.push({
            text: 'SYSTEM_BAR.MANAGE_TEAMSPACE',
            onClick: () =>
              this.openLink(
                `${(window as any).Celum.properties.saccHttpBaseAddress}/account-membership/${accAccess.accountId}`
              )
          });
        }

        return items;
      }),
      map(items => ({
        items,
        logoutItem: {
          text: 'WORKROOM.LOGOUT',
          onClick: () => this.store.dispatch(AuthLogout())
        }
      }))
    );
  }

  private getHelpContextMenuItems(): Observable<SimpleContextMenuItem[]> {
    return this.versionService.getAppVersion().pipe(
      map(appVersion => {
        const items: SimpleContextMenuItem[] = [];
        items.push({
          text: 'SYSTEM_BAR.HELP',
          onClick: () => this.openLink(this.links.help)
        });
        items.push({
          text: 'SYSTEM_BAR.TERMS_AND_CONDITIONS',
          onClick: () => this.openLink(this.links.termsConditions)
        });
        items.push({
          text: 'SYSTEM_BAR.PRIVACY_POLICY',
          onClick: () => this.openLink(this.links.privacyPolicy)
        });
        items.push(...this.addDriveDownloadConditionally());
        items.push({
          text: `${this.translateService.instant(
            'SYSTEM_BAR.VERSION'
          )} ${this.versionService.getWebVersion()}/${appVersion}`,
          onClick: () => null,
          dataComponentId: 'version'
        });
        return items;
      })
    );
  }

  private addDriveDownloadConditionally() {
    const osInfo = BrowserCheck.getOsInfo();
    let channel: string;
    switch (osInfo) {
      case 'MacOS':
        channel = (window as any).Celum.properties.drive.channel.mac;
        break;
      case 'Windows':
        channel = (window as any).Celum.properties.drive.channel.windows;
        break;
      default:
        break;
    }
    return channel && this.deviceDetectorService.isDesktop()
      ? [
          {
            text: 'SYSTEM_BAR.DOWNLOAD_DRIVE',
            onClick: () =>
              this.showDownloadDriveDialog(
                new DownloadDriveDialogConfiguration((window as any).Celum.properties.drive.downloadUrl, channel)
              )
          }
        ]
      : [];
  }

  private openLink(url: string, target = '_blank') {
    window.open(url, target);
  }

  private showDownloadDriveDialog(downloadDriveDialogConfiguration: DownloadDriveDialogConfiguration): void {
    this.dialogOpener.showDialog(DownloadDriveDialog.name, DownloadDriveDialog, downloadDriveDialogConfiguration);
  }
}
