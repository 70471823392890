import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { combineLatest, EMPTY } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, pluck, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { CelumDialogOpener } from '@celum/internal-components';
import { ImportIntentSelectFolderService } from '@celum/work/app/content-hub/services/import-intent-select-folder.service';
import { selectImportIntentById } from '@celum/work/app/content-hub/store/import-intent.selector';
import { TaskListService } from '@celum/work/app/core/api/task-list/task-list.service';
import { LoggedInPersonLoadMembership } from '@celum/work/app/core/api/teamspace-member';
import { WorkroomCreateWorkroomSucceeded } from '@celum/work/app/core/api/workroom';
import { TemplateChooserService } from '@celum/work/app/core/api/workroom-creator';
import { TenantGuard } from '@celum/work/app/core/auth/tenant.guard';
import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';
import { Paging } from '@celum/work/app/core/model';
import { TemplateDeleteOne } from '@celum/work/app/core/model/entities/template/template.actions';
import { selectAllTemplateCategories } from '@celum/work/app/core/model/entities/template-category/template-category.selectors';
import { Automator, RobotContext, RobotTriggerType } from '@celum/work/app/core/model/entities/workroom/robot.model';
import { RouterNavigationHelperService } from '@celum/work/app/core/router-navigation-helper.service';
import { ConfigurationService } from '@celum/work/app/shared/util';
import { WorkroomWizardComponent } from '@celum/work/app/workroom-wizard/components/workroom-wizard.component';

import * as workroomCreatorActions from './workroom-creator.actions';
import { workroomCreatorFeature } from './workroom-creator.selectors';
import { ImportToWorkroomsDialogComponent } from '../../dashboard/components/workroom-import-from-ch/import-to-workrooms-dialog/import-to-workrooms-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class WorkroomCreatorEffects {
  public init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workroomCreatorActions.WorkroomCreatorInit),
      tap(() => this.store.dispatch(LoggedInPersonLoadMembership())),
      switchMap(() =>
        this.store.select(selectAllTemplateCategories).pipe(
          tap(({ length }) => {
            if (length < 1) {
              this.templateChooser.fetchTemplateCategories().subscribe();
            }
          }),
          filter(({ length }) => length > 0),
          take(1),
          map(() => workroomCreatorActions.WorkroomCreatorInited())
        )
      )
    )
  );

  public createWorkroom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomCreateWorkroomSucceeded),
      pluck('workroom'),
      withLatestFrom(this.activatedRoute.queryParamMap),
      switchMap(([workroom, queryParamMap]) => {
        const importIntentId = queryParamMap.get(TenantGuard.IMPORT_INTENT_PARAM);
        if (importIntentId) {
          this.dialogOpener.closeDialog(ImportToWorkroomsDialogComponent.DIALOG_ID);

          return combineLatest([
            this.taskListService.loadTaskLists(workroom.id),
            this.store.select(selectImportIntentById(importIntentId))
          ]).pipe(
            map(([taskLists, importIntent]) => {
              const contentItemAutomator = workroom.configuration.automators.find(
                (automator: Automator) =>
                  automator.sourceContext === RobotContext.CONTENT_ITEM &&
                  automator.trigger.type === RobotTriggerType.CONTENT_HUB_ASSET_ADDED
              );

              const subMenuItems =
                importIntent.collectionCounter === 0
                  ? this.importIntentSelectFolderService.getSubMenuItemsForAssetImport(
                      workroom.configuration,
                      taskLists
                    )
                  : [];

              return this.routerNavigationHelperService.safeNavigate([`workroom/${workroom.id}/files`], {
                state: {
                  shouldOpenAddToFolderDialog: true,
                  subMenuItems,
                  importIntent,
                  watch: !!contentItemAutomator
                }
              });
            })
          );
        } else {
          return this.routerNavigationHelperService.safeNavigate(['workroom', workroom.id]);
        }
      }),
      map(() => workroomCreatorActions.WorkroomCreatorWorkroomCreated())
    )
  );

  public deleteWorkroom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workroomCreatorActions.WorkroomCreatorTemplateDelete),
      switchMap(template => this.templateChooser.delete(template).pipe(mapTo(template))),
      map(template => TemplateDeleteOne(template))
    )
  );

  public refreshTemplatesForCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workroomCreatorActions.WorkroomCreatorRefreshTemplates),
      switchMap(({ categoryIds, includePersonal }) =>
        this.templateChooser.fetchTemplates(categoryIds, includePersonal, Paging.of(0, 10))
      ),
      map(res => workroomCreatorActions.WorkroomCreatorTemplatesLoaded({ ...res }))
    )
  );

  public fetchNextTemplateBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workroomCreatorActions.WorkroomCreatorFetchNextTemplateBatch),
      withLatestFrom(this.store.select(workroomCreatorFeature)),
      switchMap(([{ categoryIds, includePersonal }, { lastOffset }]) =>
        this.templateChooser.fetchTemplates(categoryIds, includePersonal, Paging.of(lastOffset, 10))
      ),
      map(res => workroomCreatorActions.WorkroomCreatorNextTemplateBatchFetched({ ...res }))
    )
  );

  public fetchTemplateByIdAndOpenWorkroomWizardDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workroomCreatorActions.WorkroomCreatorOpenDialog),
      switchMap(({ templateId, dialogConfig }) =>
        this.templateChooser.getTemplate(templateId).pipe(
          tap(template => {
            dialogConfig.data.template = cloneDeep(template);
            this.matDialog.open(WorkroomWizardComponent, dialogConfig);
          }),
          map(template => workroomCreatorActions.WorkroomCreatorTemplateLoaded({ template }))
        )
      )
    )
  );

  public loadDefaultConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workroomCreatorActions.WorkroomCreatorLoadDefaultConfig),
      mergeMap(({ configKey }) =>
        this.configurationService.loadConfiguration(configKey).pipe(
          map(({ template }) =>
            workroomCreatorActions.WorkroomCreatorLoadDefaultConfigSuccess({
              configKey,
              config: template
            })
          ),
          catchError(err => {
            this.failureHandler.handleError(err);
            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private templateChooser: TemplateChooserService,
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private dialogOpener: CelumDialogOpener,
    private matDialog: MatDialog,
    private failureHandler: FailureHandler,
    private configurationService: ConfigurationService,
    private importIntentSelectFolderService: ImportIntentSelectFolderService,
    private taskListService: TaskListService,
    private routerNavigationHelperService: RouterNavigationHelperService
  ) {}
}
