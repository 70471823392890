<section *ngIf="(chipConfig$ | async) as chipConfig"
    class="robot-chip"
    [class.robot-chip--deletable]="deletable"
    [class.robot-chip--editable]="editable"
    [class.robot-chip--notapplied]="notApplied$ | async"
    [ngClass]="chipConfig.colorSchemeClass"
    celumTooltip
    tooltipClass="robot-tooltip"
    [componentType]="chipConfig.editEvent?.type"
    [componentInjector]="injector"
    (click)="onChipClicked()"
    (configure)="onConfigureTooltipComponent($event)">

  <section class="robot-chip_icon">
    <celum-icon [configuration]="chipConfig.icon" [clickable]="false"></celum-icon>
  </section>

  <section class="robot-chip_row1">{{ chipConfig.row1 }}</section>
  <section class="robot-chip_single-row">{{ 'ROBOTS.CHIP.NOT_APPLIED' | translate }}</section>
  <section class="robot-chip_row2" [innerHTML]="row2$ | async"></section>

  <celum-icon
      *ngIf="deletable"
      class="robot-chip_cancel"
      data-cy="remove-robot"
      [configuration]="cancelIcon"
      [clickable]="true"
      [matTooltip]="'SHARED.REMOVE'| translate"
      (click)="$event.stopPropagation(); deleteRobot.emit()">
  </celum-icon>
  <celum-icon class="robot-chip_warning"
              data-cy="robot-warning"
              [configuration]="warningIcon"
              [clickable]="false">
  </celum-icon>
</section>
