<div
    class="content-item-card"
    [class.content-item-card--selection-mode]="isInSelection"
    [class.content-item-card--active]="active"
    (contextmenu)="handleContextmenu($event, contextMenuTrigger, contentItem)">

  <span
    #contextMenuTrigger
    [matMenuTriggerFor]="contextMenu"
    class="content-item-card_ctx-menu_trigger">
  </span>

  <selection-trigger-wrapper [item]="contentItem"></selection-trigger-wrapper>

  <div class="content-item-card_file-image-container">
    <celum-icon [configuration]="folderIcon" class="content-item-card_file-image-container_image"></celum-icon>
  </div>

  <div
      class="content-item-card_footer_infobar"
      [class.content-item-card_footer_infobar--has-robots]="folderHasAppliedAutomators(contentItem)">
    <text-truncator
        class="content-item-card_footer_infobar_file-name"
        [class.content-item-card_footer_infobar_file-name--small]="small"
        [text]="contentItem.name"></text-truncator>
  </div>
  <div class="content-item-card_footer_toolbar" *ngIf="showToolbar">
    <div class="content-item-card_footer_toolbar_robot-icon">
      <current-workroom-defined-robots [folder]="contentItem">
      </current-workroom-defined-robots>
    </div>
  </div>
</div>

<mat-menu #contextMenu="matMenu" class="content-item-popup" xPosition="after">
  <ng-template matMenuContent>
    <ng-container *ngIf="!isSoftDeleted">
      <!-- first visible item of the menu needs to have 'mat-menu-item' on top level, otherwise angular submenus render weirdly-->
      <!-- cannot be celum-menu-item (or other wrapper over 'mat-menu-item') -->
      <!-- this hidden button makes sure that the top level item is always a 'mat-menu-item', not depending on feature toggles or selection states -->
      <button class="content-item-hidden" data-operation-id="noop" mat-menu-item></button>

      <button
          *ngIf="canCreateNewFolder"
          class="celum-menu-item"
          data-operation-id="create-folder"
          mat-menu-item
          (click)="createNewFolder(contentItem)">
        <celum-icon [configuration]="createFolderIcon"></celum-icon>
        <span>{{ 'FOLDER.DIALOG.CREATE.NAME' | translate }}</span>
      </button>
      <button
          class="celum-menu-item"
          data-operation-id="move-item"
          mat-menu-item
          (click)="moveItem(contentItem)">
        <celum-icon [configuration]="moveIcon"></celum-icon>
        <span>{{ getMoveLabel(folderType) }}</span>
      </button>
      <celum-menu-item
          *ngIf="canRenameFolder"
          data-operation-id="rename-folder"
          (click)="renameFolder(contentItem)">
        <celum-icon [configuration]="renameIconSmall"></celum-icon>
        <span>{{ 'FOLDER.DIALOG.RENAME' | translate }}</span>
      </celum-menu-item>
      <upload-file *ngIf="canCreateNewFile" [folder]="contentItem">
        <celum-menu-item data-operation-id="upload-file">
          <celum-icon [configuration]="uploadNewFileIcon"></celum-icon>
          <span>{{'FILES.OPERATIONS.UPLOAD' | translate}}</span>
        </celum-menu-item>
      </upload-file>

      <button
          *ngIf="sharingEnabled"
          data-operation-id="share"
          class="celum-menu-item"
          mat-menu-item
          [matMenuTriggerFor]="shareSubmenu">
        <celum-icon [configuration]="createShareIcon"></celum-icon>
        <span>{{ getShareTranslationKey() | translate }}</span>
      </button>

      <celum-menu-item
          [clickDisabled]="isDeletionDisabled"
          [disabled]="isDeletionDisabled"
          [matTooltip]="getDeleteTooltip(folderType)"
          [matTooltipDisabled]="!isDeletionDisabled"
          data-operation-id="delete-folder"
          (configurableClick)="deleteFolder(contentItem)">
        <celum-icon [configuration]="deleteIcon" [clickable]="!isDeletionDisabled"></celum-icon>
        <span>{{ getDeleteLabel(folderType) }}</span>
      </celum-menu-item>

      <menu-section
          *ngIf="canImportFromCH$ | async"
          [title]="'CONTENT_HUB.CONTEXT_MENU.SECTION_TITLE'"
          [titleIcon]="contentHubIcon">
        <celum-menu-item data-operation-id="import-as-new-file" (click)="importNewFileFromCH()">
          <celum-icon [configuration]="importNewFileFromCHIcon"></celum-icon>
          <span>{{ 'CONTENT_HUB.CONTEXT_MENU.IMPORT.FILE' | translate  }}</span>
        </celum-menu-item>
      </menu-section>
    </ng-container>

    <ng-container *ngIf="isSoftDeleted">
      <celum-menu-item data-operation-id="restore-file" (click)="restoreItem(contentItem)">
        <celum-icon [configuration]="restoreIcon"></celum-icon>
        <span>{{ getRestorationLabel(folderType) }}</span>
      </celum-menu-item>
      <celum-menu-item data-operation-id="permanent-file-delete" (click)="permanentlyDeleteItem(contentItem)">
        <celum-icon [configuration]="deleteIcon"></celum-icon>
        <span>{{ getPermanentDeleteLabel(folderType) }}</span>
      </celum-menu-item>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #shareSubmenu="matMenu" class="content-item-popup" xPosition="after">
  <ng-template matMenuContent>
    <share-menu [type]="'folder'" (createShare)="createShare(null, $event.skipDialog)"></share-menu>
  </ng-template>
</mat-menu>
