import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { merge } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Automatically load all translation files
 */
export class MultiFileTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private prefix = '',
    private suffix = '.json'
  ) {}

  public getTranslation(lang: string): Observable<any> {
    // loading custom translations for common in addition -> remember to add translations from annotations module as soon as included
    return combineLatest([
      this.http.get(`${this.prefix}${lang}${this.suffix}?v=${VERSION}`),
      this.http.get(`${this.prefix}annotations/${lang}${this.suffix}?v=${VERSION}`),
      this.http.get(`${this.prefix}common/${lang}${this.suffix}?v=${VERSION}`),
      this.http.get(`${this.prefix}sharing/${lang}${this.suffix}?v=${VERSION}`)
    ]).pipe(
      map(([app, annotations, common, sharing]: [any, any, any, any]) => merge(app, common, annotations, sharing))
    );
  }
}
