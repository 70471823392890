import { ChangeDetectionStrategy, Component, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { CelumDialog, CelumDialogConfiguration, ColorConstants, IconConfiguration } from '@celum/common-components';
import { DomHelper, ReactiveComponent } from '@celum/ng2base';
import { NotificationService } from '@celum/work/app/core';
import {
  WorkroomReopenWorkroom,
  WorkroomReopenWorkroomFailed,
  WorkroomReopenWorkroomSucceeded
} from '@celum/work/app/core/api/workroom';
import { Workroom } from '@celum/work/app/core/model/entities/workroom';
import { ColorService, PermissionUtil } from '@celum/work/app/shared/util';

import { Roles } from '../../../../../../core/model';

@Component({
  selector: 'reopen-workroom-dialog',
  templateUrl: './reopen-workroom-dialog.component.html',
  styleUrls: ['./reopen-workroom-dialog.component.less'],
  providers: [ColorService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReopenWorkroomDialog
  extends ReactiveComponent
  implements CelumDialog<ReopenWorkroomDialogConfiguration>, OnInit
{
  public isModerator = true;
  public color: string = ColorConstants.PRIMARY;
  public headerText: SafeHtml;
  public teamAssignHintText: SafeHtml;
  public recoveryHintText: SafeHtml;
  public contentHint: IconConfiguration = IconConfiguration.xLarge('icon-wr-content', '', 56);
  public teamHint: IconConfiguration = IconConfiguration.xLarge('icon-teammates', '', 56);
  public workroom: Workroom;
  public loading: boolean;
  private withRedirect: boolean;
  private optimistic: boolean;

  constructor(
    private store: Store<any>,
    private dialogRef: MatDialogRef<ReopenWorkroomDialog>,
    private permissionUtil: PermissionUtil,
    private sanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private actions$: Actions
  ) {
    super();
  }

  public ngOnInit(): void {
    this.permissionUtil.hasRoleForCurrentWorkroom(Roles.MODERATOR).subscribe(
      isModerator => this.createDialogContent(isModerator),
      () => {
        this.dialogRef.close();
        this.notificationService.error(this.translateService.instant('ERROR.CLIENT'));
      }
    );
  }

  public configure(conf: ReopenWorkroomDialogConfiguration): void {
    this.workroom = conf.workroom;
    this.color = conf.color || this.color;
    this.withRedirect = conf.withRedirect;
    this.optimistic = conf.optimistic;
  }

  public reopenWorkroom() {
    this.store.dispatch(
      WorkroomReopenWorkroom({
        workroom: this.workroom,
        canRedirect: this.withRedirect
      })
    );

    if (this.optimistic) {
      this.dialogRef.close();
    } else {
      this.loading = true;
      this.actions$
        .pipe(ofType(WorkroomReopenWorkroomSucceeded, WorkroomReopenWorkroomFailed), takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.loading = false;
          this.dialogRef.close();
        });
    }
  }

  public cancel() {
    this.dialogRef.close();
  }

  private createDialogContent(isModerator: boolean) {
    const escapedName = DomHelper.escapeHtml(this.workroom.name, false).toUpperCase();
    this.headerText = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.translateService.instant('REOPEN_WORKROOM.TITLE' + (isModerator ? '' : '_ADMIN'), { title: escapedName })
    );

    this.recoveryHintText = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.translateService.instant('REOPEN_WORKROOM.RECOVERY_HINT')
    );
    this.teamAssignHintText = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.translateService.instant('REOPEN_WORKROOM.TEAM_ASSIGN_HINT')
    );
  }
}

export class ReopenWorkroomDialogConfiguration extends CelumDialogConfiguration {
  constructor(
    public workroom: Workroom,
    public withRedirect: boolean,
    public optimistic: boolean
  ) {
    super('main');

    this.color = ColorService.getColorAsRgbString(workroom.color);
  }
}
