import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, from, of } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import { ColorConstants } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { UserSettingsService } from '@celum/work/app/core/user-settings.service';
import { selectTaskFormFieldById } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/custom-form/store/custom-form-state.selector';
import {
  ConfirmationDialog,
  ConfirmationDialogConfiguration
} from '@celum/work/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { WorkroomConstants } from '@celum/work/app/shared/util';
import { CustomFormsService } from '@celum/work/app/teamspace-management/components/forms-overview-tab/services/custom-forms.service';

import {
  AddFormToTask,
  AddFormToTaskFailed,
  AddFormToTaskSucceeded,
  CustomFormStateSetFormPanelExpandedState,
  HandleUpdateCustomFormFieldValue,
  LoadCustomFormFailed,
  LoadCustomFormSucceeded,
  LoadCustomFormToTask,
  OpenRemoveFormConfirmationDialog,
  RemoveFormFromTask,
  RemoveFormFromTaskCanceled,
  RemoveFormFromTaskFailed,
  RemoveFormFromTaskSucceeded,
  UpdateCustomFormFieldValue,
  UpdateCustomFormFieldValueSucceeded
} from './custom-form-state.actions';

@Injectable()
export class CustomFormStateEffects {
  public setFormsPanelExpandedState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomFormStateSetFormPanelExpandedState),
        tap(({ expanded }) =>
          this.userSettingsService.setApplicationSettings(
            WorkroomConstants.LOCAL_STORAGE_IS_FORM_SECTION_OPENED,
            expanded
          )
        )
      ),
    { dispatch: false }
  );

  public openRemoveFormConfirmationDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenRemoveFormConfirmationDialog),
      switchMap(({ taskId, formId }) =>
        from(
          this.dialogOpener
            .showDialog(
              'removeFormFromTask',
              ConfirmationDialog,
              new ConfirmationDialogConfiguration(
                this.translateService.instant('WORKROOM_TASK.CUSTOM_FORM.REMOVE_CONFIRMATION_DIALOG.HEADER'),
                this.translateService.instant('WORKROOM_TASK.CUSTOM_FORM.REMOVE_CONFIRMATION_DIALOG.QUESTION'),
                ColorConstants.WARNING,
                'SHARED.REMOVE'
              ),
              { restoreFocus: false }
            )
            .then(confirmed => {
              if (!confirmed) {
                return RemoveFormFromTaskCanceled();
              }

              return RemoveFormFromTask({ taskId, formId });
            })
        )
      )
    )
  );

  public addFormToTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddFormToTask),
      switchMap(({ taskId, formId }) =>
        this.customFormsService.attachFormToTask(taskId, formId).pipe(
          map(form => AddFormToTaskSucceeded({ form })),
          catchError(() => of(AddFormToTaskFailed()))
        )
      )
    )
  );

  public removeFormFromTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveFormFromTask),
      switchMap(({ taskId, formId }) =>
        this.customFormsService.removeFormFromTask(taskId, formId).pipe(
          map(() => RemoveFormFromTaskSucceeded({ taskId })),
          catchError(() => of(RemoveFormFromTaskFailed()))
        )
      )
    )
  );

  public loadFormToTaskById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCustomFormToTask),
      switchMap(({ taskId, formId }) =>
        this.customFormsService.getTaskFormById(taskId, formId).pipe(
          map(form => LoadCustomFormSucceeded({ form })),
          catchError(() => of(LoadCustomFormFailed()))
        )
      )
    )
  );

  public updateCustomFormFieldValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateCustomFormFieldValue),
      concatLatestFrom(({ fieldId }) => this.store.select(selectTaskFormFieldById(fieldId))),
      switchMap(([{ taskId, formId, fieldId, value }, { value: oldValue }]) =>
        this.customFormsService.updateCustomFormFieldValue(taskId, formId, fieldId, value).pipe(
          map(() => UpdateCustomFormFieldValueSucceeded()),
          catchError(() => of(HandleUpdateCustomFormFieldValue({ fieldId, value: oldValue }))),
          startWith(HandleUpdateCustomFormFieldValue({ fieldId, value }))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private userSettingsService: UserSettingsService,
    private customFormsService: CustomFormsService,
    private dialogOpener: CelumDialogOpener,
    private translateService: TranslateService,
    private store: Store<any>
  ) {}
}
