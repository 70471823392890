<div>
  <celum-systembar [systembar]="systembarConfig$ | async" class="workroom-app_systembar">
    <workroom-avatar small-avatar class="workroom-app_avatar" [configuration]="smallAvatarConfig$ | async"></workroom-avatar>
    <workroom-avatar big-avatar class="workroom-app_avatar" [configuration]="bigAvatarConfig$ | async"></workroom-avatar>
  </celum-systembar>

  <div class="workroom-app_content" [class.workroom-app_content--system-bar-expanded]="!systemBarCollapsed">
    <router-outlet></router-outlet>

    <loading-mask class="workroom-app_loading-mask" [hidden]="(pending$ | async) === false" background="black" [opacity]="0.3"></loading-mask>
  </div>
</div>


<operation-menu></operation-menu>
