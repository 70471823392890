<celum-dialog class="select-folders">
  <celum-dialog-header>
    {{ 'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.SELECT_FOLDERS_DIALOG.TITLE' | translate }}
  </celum-dialog-header>

  <celum-dialog-content>
    <div class="select-folders_content">
      <div class="select-folders_content_header">
        <div class="select-folders_content_header_title">
          <span class="select-folders_content_header_title-text">
            <strong>{{ 'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.SELECT_FOLDERS_DIALOG.SUBTITLE' | translate }}</strong>
            <celum-icon
              [configuration]="infoIcon"
              [matTooltip]="'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.SELECT_FOLDERS_DIALOG.INFO' | translate">
            </celum-icon>
          </span>
          <span class="select-folders_content_header_title-counter">
            <strong>{{ (store.filteredFolders$ | async).length }}</strong>
            {{(getFolderTranslationPluralOrSingular() | async) | translate}}
          </span>
        </div>
        <div>
          <search-field
           *ngIf="(store.allFolders$ | async).length !== 0"
            class="select-folders_content_header_search"
            [placeholder]="'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.SELECT_FOLDERS_DIALOG.SEARCH_FOR_FOLDERS_PLACEHOLDER'"
            (valueChange)="onSearchValueChanged($event)">
          </search-field>
        </div>
      </div>

      <div class="select-folders_content_list-container">
        <celum-simple-list
          *ngIf="(store.filteredFolders$ | async).length > 0 || (store.allFolders$ | async).length > 0;"
          class="select-folders_content_list"
          [itemTemplate]="itemTemplate"
          [items]="store.filteredFolders$ | async"
          [trackByFn]="trackByFn">
        </celum-simple-list>

        <ng-template let-item #itemTemplate>
          <div
            class="select-folders_content_list_item"
            [attr.data-cy]="'selectable-folder-' + item.folderId">
            <mat-checkbox
              [labelPosition]="'before'"
              [checked]="item | selected: (store.selectedFolders$ | async)"
              (change)="folderSelectionChanged($event.checked, item)">
              <div class="select-folders_content_list_item-name">
                <celum-icon
                  [configuration]="folderIcon"
                  [matTooltip]="'ROBOTS.CONTENT_HUB.WATCH_COLLECTION.SELECT_FOLDERS_DIALOG.FOLDER_TOOLTIP' | translate">
                </celum-icon>
                <text-truncator [text]="item.folderName" [attr.data-cy]="'files-tree-node-name'"></text-truncator>
              </div>
            </mat-checkbox>
          </div>
        </ng-template>

        <empty-page
          class="select-folders_empty_page"
          *ngIf="(store.filteredFolders$ | async).length === 0 || (store.allFolders$ | async).length === 0;"
          [config]="getEmptyPageConfiguration$() | async">
        </empty-page>
      </div>
    </div>
  </celum-dialog-content>

  <celum-dialog-footer
    *ngIf="store.selectedFolders$ | async as selectedFolders"
    cancelBtnText="COMMON.CANCEL"
    confirmBtnText="SHARED.SELECT"
    (onCancel)="closeDialog()"
    (onConfirm)="selectFolders(selectedFolders)"
    [color]="color"
    [valid]="store.isValid$ | async">
  </celum-dialog-footer>
</celum-dialog>
