<text-truncator [text]="title" class="fullscreen-dialog-header_title"></text-truncator>

<div class="fullscreen-dialog-header_actions">
  <ng-template *ngTemplateOutlet="actions"></ng-template>

  <mat-divider class="fullscreen-dialog-header_actions_divider" *ngIf="actions" [vertical]="true"></mat-divider>

  <button
      mat-button
      celum-square-button
      class="fullscreen-dialog-header_actions_close"
      data-cy="fullscreen-dialog-close"
      [disableRipple]="true"
      (click)="close()">
    <celum-icon [clickable]="true" [configuration]="closeIcon"></celum-icon>
  </button>
</div>
