import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { CelumDialogOpener } from '@celum/internal-components';
import { selectCurrentWorkroomIdParam } from '@celum/work/app/core/model/entities/workroom';
import { UiStateTenantSelected } from '@celum/work/app/core/ui-state/ui-state.actions';
import { WorkroomCreatorSetImportIntentRepositoryId } from '@celum/work/app/pages/workroom-creator/store/workroom-creator.actions';

import { TenantService } from './tenant.service';
import { ImportIntentService } from '../../content-hub/services/import-intent.service';
import { ImportIntentFetchCollectionName, ImportIntentUpsertOne } from '../../content-hub/store/import-intent.actions';
import {
  ImportToWorkroomsDialogComponent,
  ImportToWorkroomsDialogConfiguration
} from '../../pages/dashboard/components/workroom-import-from-ch/import-to-workrooms-dialog/import-to-workrooms-dialog.component';

@Injectable({ providedIn: 'root' })
export class TenantGuard implements CanActivate, CanActivateChild {
  public static readonly IMPORT_INTENT_PARAM = 'importIntent';
  public static readonly PORTAL_ID_PARAM = 'portalId';
  private isPortalApprovalEnabled: boolean;

  public constructor(
    private router: Router,
    private tenantService: TenantService,
    private store: Store<any>,
    private dialogOpener: CelumDialogOpener,
    private importIntentService: ImportIntentService
  ) {
    this.isPortalApprovalEnabled = (window as any).Celum.properties.features.portalApproval;
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.guard(next, state);
  }

  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.guard(next, state);
  }

  private guard(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const tenant = this.tenantService.getTenantFromUrl() || this.tenantService.getStoredTenant();
    const importIntent = next.queryParamMap.get(TenantGuard.IMPORT_INTENT_PARAM);
    const portalId = next.queryParamMap.get(TenantGuard.PORTAL_ID_PARAM);

    if (importIntent) {
      return this.handleImportIntent(tenant, importIntent);
    }

    if (portalId && this.isPortalApprovalEnabled) {
      return this.handlePortalId(tenant, portalId);
    }

    return this.tenantService.getAvailableTenants().pipe(
      switchMap(availableTenants => {
        return this.store.select(selectCurrentWorkroomIdParam).pipe(
          take(1),
          switchMap(workroomIdParam => {
            // no tenant in URL -> legacy URLs -> resolve by workroomId
            if (!!workroomIdParam && !this.tenantService.getTenantFromUrl()) {
              return this.tenantService.resolveByWorkroomId(workroomIdParam).pipe(
                switchMap(tenantId => this.handleTargetTenant(tenantId, availableTenants, state)),
                catchError(_ => of(this.router.createUrlTree([''])))
              );
            }

            return this.handleTargetTenant(this.tenantService.getStoredTenant(), availableTenants, state);
          })
        );
      })
    );
  }

  private handlePortalId(tenant: string, portalId: string): Observable<boolean | UrlTree> {
    return this.tenantService.getAvailableTenants().pipe(
      switchMap(availableTenants => {
        if (availableTenants.includes(tenant)) {
          this.store.dispatch(UiStateTenantSelected({ accountId: tenant }));
          this.dialogOpener.showDialog(
            ImportToWorkroomsDialogComponent.DIALOG_ID,
            ImportToWorkroomsDialogComponent,
            new ImportToWorkroomsDialogConfiguration(null, portalId),
            {
              disableClose: true
            }
          );
          return of(true);
        }
        return of(false);
      }),
      catchError(() => of(this.router.createUrlTree([''])))
    );
  }

  private handleTargetTenant(
    targetTenant: string,
    availableTenants: string[],
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const storedTenant = this.tenantService.getStoredTenant();
    if (!storedTenant || storedTenant !== targetTenant) {
      return this.tenantService.resolveCurrentOrDefault().pipe(
        map(defaultTenant => {
          // Set default tenant
          let tenant = defaultTenant;

          // If target tenant is valid, use it
          if (availableTenants.includes(targetTenant)) {
            tenant = targetTenant;
          }

          this.tenantService.navigateWithTenant(tenant, state.url);
          return false;
        })
      );
    }

    if (!availableTenants.includes(storedTenant)) {
      this.tenantService.removeStoredTenant();
      this.tenantService.navigateWithTenant(availableTenants[0], '/dashboard');
      return of(false);
    }

    this.store.dispatch(UiStateTenantSelected({ accountId: targetTenant }));
    return of(true);
  }

  private handleImportIntent(tenant: string, importIntentId: string) {
    return combineLatest([
      this.importIntentService.getImportIntent(importIntentId),
      this.tenantService.getAvailableTenants()
    ]).pipe(
      switchMap(([importIntent, availableTenants]) => {
        if (availableTenants.includes(tenant)) {
          this.store.dispatch(UiStateTenantSelected({ accountId: tenant }));
          this.store.dispatch(
            WorkroomCreatorSetImportIntentRepositoryId({
              importIntentRepositoryId: importIntent.repositoryId
            })
          );
          if (importIntent.collectionCounter > 0) {
            this.store.dispatch(
              ImportIntentFetchCollectionName({
                repositoryId: importIntent.repositoryId,
                collectionId: importIntent.collectionId
              })
            );
          }
          this.store.dispatch(ImportIntentUpsertOne({ importIntent }));
          this.dialogOpener.showDialog(
            ImportToWorkroomsDialogComponent.DIALOG_ID,
            ImportToWorkroomsDialogComponent,
            new ImportToWorkroomsDialogConfiguration(importIntent),
            {
              disableClose: true
            }
          );
          return of(true);
        }

        return of(false);
      }),
      catchError(() => of(this.router.createUrlTree([''])))
    );
  }
}
