import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { FileType } from '@celum/work/app/core/model/entities/file/file.model';
import {
  ConfirmationDialog,
  ConfirmationDialogConfiguration
} from '@celum/work/app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ContentItemRestrictedDialogOpener {
  constructor(
    private dialogOpener: CelumDialogOpener,
    private translateService: TranslateService
  ) {}

  public showDialog(typeKey: string) {
    this.dialogOpener.showDialog(
      'show-content-item-restricted-dialog',
      ConfirmationDialog,
      this.getOpenRestrictionDialogConfig(typeKey),
      {
        restoreFocus: false
      }
    );
  }

  private getOpenRestrictionDialogConfig(typeKey: string): ConfirmationDialogConfiguration {
    return new ConfirmationDialogConfiguration(
      this.translateService.instant(
        typeKey === FileType.TYPE_KEY ? 'TRASH_BIN.INFO_DIALOG.HEADLINE_FILE' : 'TRASH_BIN.INFO_DIALOG.HEADLINE_FOLDER'
      ),
      this.translateService.instant(
        typeKey === FileType.TYPE_KEY ? 'TRASH_BIN.INFO_DIALOG.BODY_FILE' : 'TRASH_BIN.INFO_DIALOG.BODY_FOLDER'
      ),
      ColorConstants.PRIMARY,
      'SHARED.OK',
      IconConfiguration.xLarge('info', '', 64).withColor(ColorConstants.PRIMARY),
      'deleted-file-open-restriction'
    );
  }
}
