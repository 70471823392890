<div class="task-list-header" [style.backgroundColor]="listColor">
  <div
      class="task-list-header_name-wrapper"
      [style.cursor]="(isVisitor$ | async) || (updatePermission && updatePermission !== 'permission.allowed') || !hasPermissionToMoveTaskList  ? 'default' : 'grab'"
      [matTooltip]="listNameFormCtrl.value | uppercase">
    <mat-form-field
        celum-form-field
        celum-inline-form-field
        substrate="light"
        size="small"
        class="task-list-header_name-input"
        [style.pointer-events]="(isVisitor$ | async) || (updatePermission && updatePermission !== 'permission.allowed') ? 'none' : 'auto'"
        (onSaved)="changeName($event)"
        (onCancel)="cancelEdit()">
      <span matTextPrefix class="task-list-header_prefix">
        <ng-content select="[taskcount]"></ng-content>
      </span>
      <input
          matInput
          data-cy="task-list-name"
          [formControl]="listNameFormCtrl"
          [disabled]="updatePermission && updatePermission !== 'permission.allowed'"
          [maxLength]="30"
          [minLength]="1"/>
      <celum-inline-form-field-buttons
          matSuffix
          [class.task-list-name-buttons--hidden]="(isVisitor$ | async) || (updatePermission && updatePermission !== 'permission.allowed')">
      </celum-inline-form-field-buttons>
    </mat-form-field>
  </div>

  <button
      *ngIf="!taskList?.isPlaceholder && (dynamicColumnSize || (deletePermission === 'permission.allowed' && updatePermission === 'permission.allowed'))"
      mat-button
      celum-square-button
      class="task-list-header_menu-trigger"
      [isRippleLight]="true">
    <celum-icon
        data-component-id="menu-trigger"
        class="task-list-header_menu-trigger_icon"
        [class.task-list-header_menu-trigger_icon--draggable]="(isVisitor$ | async) === false && updatePermission && updatePermission === 'permission.allowed'"
        [configuration]="menuIcon"
        [matTooltip]="'SHARED.MORE_ACTIONS' | translate"
        [matMenuTriggerFor]="menuRef">
    </celum-icon>
  </button>

  <mat-menu xPosition="before" #menuRef="matMenu" class="task-list-header_popup task-list-menu">
    <ng-template #menuItems>
      <section class="task-list-menu_section">
        <ng-content select="[menuitem=add-new-task]"></ng-content>
        <ng-template [ngxPermissionsOnly]="[deletePermission]">
          <celum-menu-item *ngIf="deletable"
              class="task-list-header_delete"
              data-cy="delete-task-list"
              data-operation-id="delete-list"
              (click)="deleteTaskList()">
            <celum-icon
                [configuration]="deleteIcon"
                data-component-id="menu-trigger">
            </celum-icon>
            <span>{{'TASK_BOARD.ACTIONS.DELETE_LIST' | translate}}</span>
          </celum-menu-item>
        </ng-template>
        <mat-divider></mat-divider>
      </section>
      <color-menu-item *ngIf="canChangeTaskListColor$ | async"
                       data-operation-id="change-color"
                       [colors]="taskListColors"
                       [currentColor]="taskList.color"
                       (selected)="changeColorTaskList($event)"></color-menu-item>

      <task-list-columns-menu-item *ngIf="dynamicColumnSize"
                                   [visibleColumns]="visibleColumnCount$ | async"
                                   (columnsChanged)="changeVisibleColumns($event)">
      </task-list-columns-menu-item>

    </ng-template>
    <ng-template [ngTemplateOutlet]="menuItems"></ng-template>
  </mat-menu>

</div>
