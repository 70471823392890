<fullscreen-dialog cdkFocusInitial class="file-detail" [title]="(file$ | async)?.name" [attr.data-cy]="(file$ | async)?.name">
  <mat-drawer-container class="file-detail_drawer-container" [autosize]="true">
    <mat-drawer
        *ngIf="dialogData?.showElements?.sidebar ?? true"
        class="file-detail_drawer-container_side"
        [class.file-detail_drawer-container_side_show_tasks_tab]="dialogData?.showElements?.tasksTab ?? true"
        mode="side"
        opened
        position="end"
        [disableClose]="true"
        #matDrawer>
      <mat-tab-group [dynamicHeight]="true" [(selectedIndex)]="selectedTabIndex" (animationDone)="onTabAnimationDone()">
        <mat-tab [label]="'FILE_DETAIL.TABS.ACTIVITIES' | translate | uppercase">
          <comments-activities-panel
              [withMarkers]="true"
              [targetEntity]="file$ | async"
              [commentPlaceholder]="'FILE_DETAIL.COMMENTS.GLOBAL'"
              [commentingEnabled]="(comparingEnabled$ | async) === false"
              [filterEnabled]="(comparingEnabled$ | async) === false">
              [focusComment]="shouldFocusComment">
          </comments-activities-panel>
        </mat-tab>

        <mat-tab [label]="'FILE_DETAIL.TABS.DETAILS' | translate | uppercase">
          <ng-template matTabContent>
            <file-details [file]="file$ | async" [fileVersion]="fileVersion$ | async" [showFileExplorerLink]="(dialogData.task$ | async) !== null" ></file-details>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="dialogData?.showElements?.tasksTab ?? true" [label]="'FILE_DETAIL.TABS.TASKS' | translate | uppercase" >
          <ng-template matTabContent>
            <file-tasks [file]="file$ | async"></file-tasks>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-drawer>

    <mat-drawer-content class="file-detail_drawer-content">
      <workrooms-annotations
          [keyboardEnabled]="keyboardEnabled"
          [file]="file$ | async"
          [task]="dialogData.task$ | async"
          [fileVersion]="fileVersion$ | async"
          [compareFileVersion]="compareFileVersion$ | async"
          [hasNext]="dialogData.hasNext() | async"
          [hasPrevious]="dialogData.hasPrevious() | async"
          [drawerOpened]="matDrawer?.opened"
          (toggleDrawer)="matDrawer.toggle()"
          [showHeader]="dialogData?.showElements?.annotationHeader ?? true"
          [showNavigationButtons]="dialogData?.showElements?.navigationButtons ?? true"
          [viewContext]="dialogData?.viewContext"
          (showNext)="dialogData?.showNext()"
          (showPrevious)="dialogData?.showPrevious()"
          (escapePressed)="onEscapePressed()">
      </workrooms-annotations>
    </mat-drawer-content>
  </mat-drawer-container>

  <preview-items
      *ngIf="(dialogData?.previewItems$ | async)?.length > 1"
      [attachments]="dialogData?.previewItems$ | async"
      [task]="dialogData?.task$ | async"
      [activeAttachmentId]="(file$ | async)?.id">
  </preview-items>
</fullscreen-dialog>
