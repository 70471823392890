<div class="workroom-details_wrapper">
  <form class="workroom-details" [formGroup]="workroomWizardForm">
    <div class="workroom-details_header">{{ translations.details | translate }}</div>
    <div class="workroom-details_option" data-component-id="name">
      <div
          class="workroom-details_option_label input-label"
          [class.workroom-details_option_label--invalid]="!workroomWizardForm.get(nameControlName).valid && workroomWizardForm.get(nameControlName).dirty">
        {{ translations.name | translate }}
      </div>
      <mat-form-field cdkFocusInitial celum-form-field size="small" class="workroom-details_form-field" [reserveHintSpace]="true">
        <input
            autofocus
            matInput
            data-cy="workroom-details-name"
            class="workroom-details_name-input"
            [formControlName]="nameControlName"
            [autoFocus]="true"
            [placeholder]="translations.namePlaceholder | translate"
            [maxLength]="validationValues.maxNameLength">
        <mat-hint *ngIf="showRemainingNameCharsHint">{{ 'INPUT_HINTS.REMAINING_CHARS' | translate: remainingNameChars }}</mat-hint>
        <mat-error *ngIf="workroomWizardForm.get(nameControlName).hasError('required')">{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div class="workroom-details_option" data-component-id="description">
      <div class="workroom-details_option_label input-label">{{ translations.description | translate }}</div>
      <div class="workroom-details_description-wrapper" [ngClass]="{'workroom-details_description-wrapper--toolbar-hidden': !descriptionEditor.toolbarVisible}">
        <celum-quill-editor
            data-cy="workroom-details-description"
            type="workroom-description"
            [quillMaxLength]="validationValues.maxDescriptionLength"
            [readonly]="false"
            [canUpload]="false"
            [canMention]="false"
            [toolbarVisible]="false"
            [placeholder]="translations.descriptionPlaceholder | translate"
            [jsonString]="workroomWizardForm.get(this.descriptionControlName).value"
            (jsonStringChange)="updateDescription($event)"
            (click)="descriptionEditor.toolbarVisible = true"
            (onBlur)="descriptionEditor.toolbarVisible = false"
            #descriptionEditor>
        </celum-quill-editor>
        <mat-hint *ngIf="showRemainingDescriptionCharsHint">{{ 'INPUT_HINTS.REMAINING_CHARS' | translate: remainingDescriptionChars }}</mat-hint>
      </div>
    </div>
    <!-- Workroom details container -->
    <div class="workroom-details_due-date_and_category-select_container">
      <div data-component-id="due-date" class="workroom-details_due-date">
        <div class="workroom-details_option">
          <div class="workroom-details_option_label input-label">{{ translations.dueDate | translate }}</div>
          <due-date
              class="workroom-details_option_date"
              [attr.data-due-date]="dueDate"
              [dueDate]="+template.dueDate"
              [hasPermission]="permissionStrategy.canEditWorkroom() | async"
              [backgroundColor]="dueDateBackgroundColor"
              [dueDateParentComponent]="'WORKROOM'"
              (dueDateChange)="onDueDateChanged($event)">
          </due-date>
        </div>
      </div>

      <!-- Workroom repository -->
      <div
          *ngIf="showRepositorySelection$ | async"
          class="workroom-details_option workroom-details_option_repository"
          data-component-id="repository-select">
        <div class="workroom-details_option_label">{{ 'WORKROOM_WIZARD.DIALOG.WORKROOM.REPOSITORY_SELECTION' | translate }}</div>
        <mat-form-field
            celum-form-field
            size="medium"
            class="workroom-details_form-field_select"
            data-cy="workroom-details-repository-form-field"
            [class.workroom-details_form-field_select--disabled]="disabledRepositorySelection$ | async">
          <mat-select #repoDropdown
              data-cy="workroom-details-repository"
              formControlName="repositoryId"
              [panelClass]="['celum-dropdown-panel', 'workroom-details_dropdown-panel']"
              matTooltip="{{ selectedRepositoryId }}"
              matSelectTooltipAutoDisabling>
            <info-note
                data-cy="info-note-repository-selection-warning"
                text="WORKROOM_WIZARD.DIALOG.WORKROOM.REPOSITORY_SELECTION_WARNING">
            </info-note>
            <mat-option
                option value=""
                selected>{{ 'WORKROOM_WIZARD.DIALOG.WORKROOM.REPOSITORY_SELECTION_PLACEHOLDER' | translate }}</mat-option>
            <mat-option
                *ngFor="let repositoryId of repositoryIds$ | async; trackBy: repositoryTrackByFn"
                [attr.data-cy]="'workroom-details-repository-option-' + repositoryId"
                [attr.repository]="repositoryId"
                [value]="repositoryId">
              <text-truncator
                  [updatedNeededEvent]="repoDropdown.openedChange"
                  [text]="repositoryId">
              </text-truncator>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Workroom Template category -->
      <div
          *ngIf="(isCategoriesVisible$ | async) && workroomWizardType.targetEntity === 'TEMPLATE'"
          class="workroom-details_option"
          data-component-id="category-select">

        <!-- Workroom Template category label -->
        <div class="workroom-details_option_label">{{ 'WORKROOM_WIZARD.DIALOG.TEMPLATE.COMPANY_SELECTION' | translate }}</div>

        <!-- Workroom Template category name -->
        <mat-form-field
            celum-form-field
            size="medium"
            class="workroom-details_form-field_select"
            data-cy="workroom-details-category"
            [class.workroom-details_form-field_select--disabled]="workroomWizardType.editMode">

          <mat-select
              formControlName="categoryId"
              [panelClass]="['celum-dropdown-panel', 'workroom-details_dropdown-panel']">
            <mat-option
                *ngFor="let category of categories$ | async; trackBy: idTrackByFn" [value]="category.id"
                [attr.data-cy]="'workroom-details-category-option-' + category.id">
              {{ category | templateCategoryName }}
            </mat-option>
            <mat-option
                [value]="'personal'"
                [attr.data-cy]="'workroom-details-category-option-personal'">
              {{ 'TEMPLATE_CHOOSER.PERSONAL_CATEGORY' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="workroomWizardType.targetEntity === 'WORKROOM' && teamspace$ | async as teamspace">
      <div class="workroom-details_save-template_label">{{'WORKROOM_WIZARD.DIALOG.WORKROOM.SAVE_TEMPLATE_LABEL' | translate}}</div>
        <button
            mat-flat-button celum-button size="small"
            class="workroom-details_save-template_button"
            (click)="openDialog(teamspace)">
          <celum-icon [configuration]="saveTemplateIcon"></celum-icon>
          {{'WORKROOM_WIZARD.DIALOG.WORKROOM.SAVE_TEMPLATE_BUTTON' | translate}}
        </button>
    </ng-container>

  </form>

  <div class="workroom-details_people">
    <div class="workroom-details_header">{{ 'WORKROOM_WIZARD.DIALOG.PEOPLE_SECTION' | translate }}</div>
    <workroom-wizard-people
        [teamspaceId]="teamspaceId$ | async"
        [template]="template"
        [workroomWizardType]="workroomWizardType"
        [workroomWizardForm]="workroomWizardForm"
        [translations]="translations">
    </workroom-wizard-people>
  </div>
</div>
