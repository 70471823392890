import { CelumProperties } from './celum-properties.model';
import { InjectedCelumProperties } from './injected-celum-properties.model';
import { mergeDeep } from '../util/data-util';

/** Expected global object */
declare let Celum: { properties: CelumProperties };

const defaultCelumProperties: CelumProperties = {
  defaultLanguage: 'en',
  availableLanguages: ['en', 'de', 'fr'],
  translationPath: '__pathToBuild__',
  ng2ProdMode: false,
  buildTimestamp: '',
  httpBaseAddress: 'http://localhost:8091/frontend',
  sharingHttpBaseAddress: 'http://localhost:8090',
  librariesHttpBaseAddress: 'http://localhost:8090',
  librariesUrl: 'http://localhost:8080',
  experienceHttpBaseAddress: 'http://localhost:8090',
  experienceUrl: 'http://localhost:8080',
  supportPortalUrl: 'https://service.celum.com/plugins/servlet/desk',
  logCommunication: false,
  logSendMessages: true,
  logPerformanceMeasurements: true,
  liveUpdateInterval: 60000,

  tutorialVideoLink: 'http://www.123contactform.com',
  helpLinks: {
    en: 'http://www.123contactform.com/index.html',
    de: 'http://www.123contactform.com/index.html',
    fr: 'http://www.123contactform.com/index.html'
  },
  privacyPolicyLink: 'http://www.123contactform.com',
  termsConditionsLink: 'http://www.123contactform.com',

  autoUpdateQueries: true,

  logLevel: 4,
  consoleLogLevel: 3,
  stackTraceLines: 40, // make sure not to miss the actual source of the issue

  queryRetryDelay: 5000,
  queryRetries: 3,
  queryRetryForConnectionLossOnly: false,
  pingInterval: 3500,
  pingAddress: '',
  uiLogsReportInterval: 10000,
  redirectToUrlOn401: '',
  redirectToSelfOn403: false,
  maxConcurrentUploads: 5,
  connectionCheck: true,
  appProperties: {},
  authentication: {
    clientId: '',
    b2cDomain: 'login-dev.celum.net',
    b2cTenantId: '',
    aDDomain: 'celumdev',
    redirectUri: 'http://localhost:4200',
    redirectTokenUri: 'http://localhost:4200',
    logoutRedirectUri: 'http://localhost:4200',
    defaultSigninPolicy: 'B2C_1A_signup_signin',
    saccUrl: 'https://sacc-dev.celum.net/api',
    saccAccountMemberUrl: 'https://sacc-dev.celum.net/account-membership/$accountId$/members',
    applicationLicenseType: '',
    applicationFallbackPagesPerLanguage: {}
  },
  applicationName: '',
  systemBar: {
    contentHubFallbackUrlPerLanguage: {
      en: 'https://www.celum.com/${language}/digital-asset-management-software/',
      de: 'https://www.celum.com/${language}/digital-asset-management-software/',
      fr: 'https://www.celum.com/${language}/celum-digital-asset-management/'
    },
    librariesFallbackUrlPerLanguage: {
      en: 'https://www.celum.com/en/media-portals-libraries/',
      de: 'https://www.celum.com/de/media-portale-libraries/',
      fr: 'https://www.celum.com/en/media-portals-libraries/'
    },
    experienceFallbackUrlPerLanguage: {
      en: 'https://www.celum.com/en/media-portals-libraries/',
      de: 'https://www.celum.com/de/media-portale-libraries/',
      fr: 'https://www.celum.com/en/media-portals-libraries/'
    },
    workAppUrl: 'https://workrooms-dev.celum.net',
    librariesAppUrl: 'https://libraries-dev.celum.net',
    experienceAppUrl: 'https://experience-dev.celum.net',
    helpUrls: {
      helpCenter: {
        en: 'http://helpstaging.celum.world/help_celum_com/en/index.htm',
        de: 'http://helpstaging.celum.world/help_celum_com/de/index.htm',
        fr: 'http://helpstaging.celum.world/help_celum_com/en/index.htm'
      },
      privacyPolicy: {
        en: 'http://helpstaging.celum.world/help_celum_com/en/topic.htm#t=legal/celum-privacy-policy.htm',
        de: 'http://helpstaging.celum.world/help_celum_com/de/topic.htm#t=legal/celum-privacy-policy.htm',
        fr: 'http://helpstaging.celum.world/help_celum_com/en/topic.htm#t=legal/celum-privacy-policy.htm'
      },
      termsConditions: {
        en: 'http://helpstaging.celum.world/help_celum_com/en/topic.htm#t=legal/celum-terms-of-service.htm',
        de: 'http://helpstaging.celum.world/help_celum_com/de/topic.htm#t=legal/celum-terms-of-service.htm',
        fr: 'http://helpstaging.celum.world/help_celum_com/en/topic.htm#t=legal/celum-terms-of-service.htm'
      }
    }
  },
  applicationInsights: {
    instrumentationKey: '',
    enableAutoRouteTracking: false,
    endpointUrl: 'https://analytics.celum.net',
    samplingPercentage: null
  }
};

/** Used as temporary var to merge Global and Default properties */
let tempCelumProperties: any;
let globalCelumObjectExists = true;

try {
  // eslint-disable-next-line no-unused-expressions
  Celum;
} catch (err) {
  globalCelumObjectExists = false;
}

if (!globalCelumObjectExists) {
  console.debug('CelumProperties: using default values!');
  // provide default values...
  tempCelumProperties = defaultCelumProperties;
} else {
  if (Celum.properties.consoleLogLevel <= 1) {
    console.debug('CelumProperties: merging configured properties with defaults...');
  }

  // override default properties with the global ones.
  // If new global property is added, it will be not reachable in TypeScript code until TypeScript interface is not updated
  tempCelumProperties = mergeDeep(defaultCelumProperties, Celum.properties); // deep merge to not override nested properties completely (e.g. nova)
}

/**
 * Runtime properties for celum application
 */
export class CelumPropertiesProvider {
  private static singleton: InjectedCelumProperties;

  /**
   * @returns singleton
   */
  static get properties(): CelumProperties {
    if (!this.singleton) {
      this.singleton = new InjectedCelumProperties(tempCelumProperties);

      if (this.singleton.consoleLogLevel <= 1) {
        console.debug('CelumProperties: Created by CelumPropertiesProvider:  ' + JSON.stringify(this.singleton, null, '  '));
      }
    }

    return this.singleton;
  }
}
