import { EntityState } from '@ngrx/entity';
import { schema } from 'normalizr';

import { EntityType } from '@celum/core';

import { Entity } from '../../entity';

export interface Portal extends Entity<number, PortalType> {
  name: string;
  link: string;
}

export class PortalType implements EntityType {
  public static readonly TYPE_KEY: string = 'Portal';

  private static _instance: PortalType;

  public id = PortalType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): PortalType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(): schema.Entity {
    return new schema.Entity(PortalType.TYPE_KEY, {});
  }
}

export interface PortalState extends EntityState<Portal> {}
