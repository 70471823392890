<celum-dialog [hideCloseAllBtn]="true" class="download-drive-dialog">

  <celum-dialog-header class="download-drive-dialog_header">
    <div class='download-drive-dialog_title'>{{ 'SYSTEM_BAR.DOWNLOAD_DRIVE' | translate }}</div>
  </celum-dialog-header>

  <celum-dialog-content class="download-drive-dialog_content">
    <celum-icon [configuration]="syncingIcon" class="download-drive-dialog_content-syncing-icon"></celum-icon>
    <div class="download-drive-dialog_content-sync-file">{{ 'DOWNLOAD_DRIVE.SYNC_FILES_TO_YOUR_LOCAL_SYSTEM' | translate }}</div>
  </celum-dialog-content>

  <celum-dialog-footer
      (onCancel)="dialogRef.close()"
      (onConfirm)="download()"
      [color]="color"
      cancelBtnText='COMMON.CANCEL'
      confirmBtnText='DOWNLOAD_DRIVE.DOWNLOAD'
      class="dialog-footer download-drive_footer">
  </celum-dialog-footer>
</celum-dialog>
