import { createSelector } from '@ngrx/store';

import { itemLinkAdapter } from './item-link.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities.state';

const { selectEntities, selectAll } = itemLinkAdapter.getSelectors();

export const selectItemLinks = createSelector(selectEntitiesState, (state: EntitiesState) => state.itemLinks);
export const selectAllItemLinks = createSelector(selectItemLinks, selectAll);
export const selectItemLinksEntities = createSelector(selectItemLinks, selectEntities);
export const selectItemLinkById = (id: number) => createSelector(selectItemLinksEntities, itemLink => itemLink[id]);
