import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { ReactiveComponent } from '@celum/ng2base';
import { CommentAddMarker, CommentRemoveSelection } from '@celum/work/app/core/model/entities/comment/comment.actions';
import { File, FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { selectFileById } from '@celum/work/app/core/model/entities/file/file.selectors';
import { FileVersion, FileVersionType } from '@celum/work/app/core/model/entities/file-version/file-version.model';
import { UiStatePushViewContext, UiStateRemoveSpecificContext } from '@celum/work/app/core/ui-state/ui-state.actions';
import { UiViewContext } from '@celum/work/app/core/ui-state/ui-state.model';
import { VersionSwitcherResetState } from '@celum/work/app/files/detail/version-switcher/store/version-switcher.actions';
import {
  selectCompareFileVersion,
  selectCompareFileVersionId,
  selectSelectedFileVersion
} from '@celum/work/app/files/detail/version-switcher/store/version-switcher.selectors';
import { FileDetailDialogData } from '@celum/work/app/pages/workroom/pages/files/pages/file-detail/components/file-detail-dialog/model/file-detail-dialog-data.model';
import { notNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

import { selectFileForDetail } from '../../store/file-detail.selectors';

@Component({
  selector: 'file-detail-dialog',
  templateUrl: './file-detail-dialog.component.html',
  styleUrls: ['./file-detail-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FileDetailDialogComponent extends ReactiveComponent implements OnInit, OnDestroy {
  @ViewChild('matDrawer') public matDrawer: MatDrawer;

  public keyboardEnabled = false;
  public selectedTabIndex = 0;

  public fileVersion$: Observable<FileVersion>;
  public file$: Observable<File>;
  public compareFileVersion$: Observable<FileVersion>;
  public comparingEnabled$: Observable<boolean>;
  public shouldFocusComment = false;

  private selectedFile$: Observable<File>;
  private markerAdded = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: FileDetailDialogData,
    private store: Store<any>,
    private actions$: Actions,
    private dialogRef: MatDialogRef<any>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.store.dispatch(UiStatePushViewContext({ context: this.dialogData.viewContext }));

    this.selectedFile$ = this.dialogData.fileId$.pipe(
      switchMap(fileId => (fileId ? this.store.select(selectFileById(fileId)) : this.store.select(selectFileForDetail)))
    );

    this.file$ = this.selectedFile$.pipe(
      filter(file => !!file),
      distinctUntilChanged(FileType.equalsByIdAndActiveVersionIdAndName)
    );

    this.setupFileVersionChanges();
    this.setupCompareFileVersion();

    this.selectedTabIndex = window.history?.state?.selectedTabIndex ?? 0;
    this.switchTabIfMarkerAdded();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.dialogData.viewContext !== UiViewContext.COMMENT_ATTACHMENT_DIALOG) {
      this.store.dispatch(VersionSwitcherResetState());
    }
    this.store.dispatch(UiStateRemoveSpecificContext({ specificContext: UiViewContext.FILE_DETAIL }));
    this.store.dispatch(CommentRemoveSelection());
  }

  // need to wait for the drawer to be opened before focusing the comment
  public onTabAnimationDone(): void {
    this.shouldFocusComment = false;
    if (this.markerAdded) {
      this.shouldFocusComment = true;
      this.markerAdded = false;
    }
  }

  public onEscapePressed(): void {
    this.dialogRef.close();
  }

  private switchTabIfMarkerAdded(): void {
    this.actions$.pipe(takeUntil(this.unsubscribe$), ofType(CommentAddMarker)).subscribe(() => {
      this.markerAdded = true;
      this.selectedTabIndex = 0;
    });
  }

  private setupFileVersionChanges(): void {
    const selectedFileVersion$ = this.dialogData.fileId$.pipe(
      filter(fileId => !!fileId),
      switchMap(() => this.store.select(selectSelectedFileVersion))
    );

    this.fileVersion$ = combineLatest([
      selectedFileVersion$,
      this.file$.pipe(switchMap(file => file.activeVersion(this.store)))
    ]).pipe(
      map(([selectedFileVersion, activeVersion]) => selectedFileVersion ?? activeVersion),
      distinctUntilChanged((prev, curr) => FileVersionType.equals(prev, curr))
    );
  }

  private setupCompareFileVersion(): void {
    this.compareFileVersion$ = this.store
      .select(selectCompareFileVersion)
      .pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id));

    this.comparingEnabled$ = this.store.select(selectCompareFileVersionId).pipe(map(id => notNullOrUndefined(id)));
  }
}
