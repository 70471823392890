import { createAction, props } from '@ngrx/store';

import { FolderPropertiesToUpdate } from '@celum/work/app/core/model/entities/folder/folder.model';

import { File, FilePropertiesToUpdate, FileTaskCount } from './file.model';
import { ContentHubConnection } from '../content-item/content-item.model';

export const FileUpsertOne = createAction('[File] Upsert One', props<{ file: File; propertiesToUpdate?: string[] }>());

export const FileUpsertMany = createAction(
  '[File] Upsert Many',
  props<{ files: File[]; propertiesToUpdate?: string[] }>()
);

export const FileDeleteOne = createAction('[File] Delete One', props<{ id: string }>());

export const FileDeleteMany = createAction('[File] Delete Many', props<{ ids: string[] }>());

export const FileUpdate = createAction(
  '[File] Update',
  props<{ file: File; propertiesToUpdate: FilePropertiesToUpdate }>()
);

export const FileUpdateSucceeded = createAction(
  '[File] Update succeeded',
  props<{ file: File; updatedProperties: FolderPropertiesToUpdate }>()
);

export const FileUpdateFailed = createAction('[File] Update failed', props<{ file: File }>());

export const FileAddContentHubConnection = createAction(
  '[File] Add ContentHubConnection',
  props<{ id: number; contentHubConnection: ContentHubConnection }>()
);

export const FileFetchTaskCounts = createAction('[File] Fetch Task Counts', props<{ fileIds: string[] }>());

export const FileFetchTaskCountsSucceeded = createAction(
  '[File] Fetch Task Counts succeeded',
  props<{ fileTaskCounts: FileTaskCount[] }>()
);

export const FileFetchTaskCountsFailed = createAction(
  '[File] Fetch Task Counts failed',
  props<{ fileIds: string[] }>()
);
